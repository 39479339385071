// Footer

.footer {
  background-color: $blue-light;
  padding: 45px 0 20px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 58px 0 70px 0; }
  .container {
    position: relative;
    z-index: 1; }
  .logo {
    margin-bottom: 11px;
    text-align: $right;
    img {
      width: auto;
      max-height: 73px;
      @media only screen and (max-width: $r-768 - 1) {
        max-height: 57px; } } }
  .text {
    max-width: 255px;
    text-align: $right;
    margin-bottom: 40px;
    p {
      font-weight: 300;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 16px;
        line-height: 26px;
        color: rgba(248, 248, 248, 0.81); } } }
  .socials {
    ul {
      display: flex;
      margin-bottom: 0;
      li {
        margin-left: 15px; } } }
  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.19);
    margin: 55px 0 25px 0; }
  .menu {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; }
    a {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
      color: rgba(255, 255, 255, 0.51); }
    .sub-menu {
      padding-left: 20px;
      @media only screen and (max-width: $r-992) {
        padding-left: 0; }
      li {
        margin-bottom: 9px;
        a {
          font-size: 16px;
          line-height: 26px;
          color: white; } } } }
  .copyright {
    text-align: $right;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 24px; }
    p {
      margin-bottom: 0;
      color: rgba(255, 255, 255, 0.6); } }
  .created {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media only screen and (max-width: $r-768 - 1) {
      justify-content: flex-start; }
    p {
      margin-bottom: 0;
      color: rgba(255, 255, 255, 0.6); }
    svg {
      margin-left: 5px; } }
  .vectors {
    position: absolute;
    left: 0;
    bottom: 0;
    @media only screen and (max-width: $r-768 - 1) {
      bottom: initial;
      top: 0; }
    svg {
      &:first-child {
        @media only screen and (max-width: $r-768 - 1) {
          display: none; } }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: $r-768 - 1) {
          display: block; } } } } }
