.page-id-249 {
  .header-navigation {
    background-color: transparent; }
  .nav-up {
    .header-navigation {
      background-color: transparent; } } }

// Download hero

.download-hero {
  display: flex;
  justify-content: center;
  min-height: 270px;
  padding-top: 155px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media only screen and (max-width: $r-768 - 1) {
    min-height: 120px;
    padding-top: 185px; }
  //&.add-closed
  //  margin-top: 49px
  .vectors {
    max-width: 100%;
    position: absolute;
    top: 95px;
    z-index: 1;
    @media only screen and (max-width: $r-768 - 1) {
      top: initial; }
    svg {
      &:first-child {
        @media only screen and (max-width: $r-768 - 1) {
          display: none; } }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: $r-768 - 1) {
          display: block; } }
      @media only screen and (max-width: $r-1200) {
        max-width: 100%; }
      g {
        &:first-of-type {
          path, rect {
            animation: movedelement 8s infinite linear; } }
        &:nth-of-type(2) {
          path, rect {
            animation: movedelement 6s infinite linear; } }
        &:nth-of-type(3) {
          path, rect {
            animation: movedelement2 7s infinite linear; } }
        &:nth-of-type(4) {
          path, rect {
            animation: movedelement2 3s infinite linear; } }
        &:nth-of-type(5) {
          path, rect {
            animation: movedelement 9s infinite linear; } }
        &:nth-of-type(6) {
          path, rect {
            animation: rotatedelement2 10s infinite linear; } }
        &:nth-of-type(7) {
          path, rect {
            animation: movedelement2 13s infinite linear; } }
        &:nth-of-type(8) {
          path, rect {
            animation: movedelement 9s infinite linear; } }
        &:nth-of-type(9) {
          path, rect {
            animation: movedelement 8s infinite linear; } }
        &:nth-of-type(10) {
          path, rect {
            animation: movedelement2 10s infinite linear; } }
        &:nth-of-type(11) {
          path, rect {
            animation: rotatedelement 12s infinite linear; } }
        &:nth-of-type(12) {
          path, rect {
            animation: movedelement 7s infinite linear; } }
        &:nth-of-type(13) {
          path, rect {
            animation: movedelement2 10s infinite linear; } }
        &:nth-of-type(14) {
          path, rect {
            animation: movedelement2 6s infinite linear; } } } } } }

// Downloads form

.download-form {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 160px 0 180px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 91px 0 125px 0;
    flex-direction: column; }
  .container {
    position: relative;
    z-index: 2; }
  .image {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: absolute;
    top: 158px;
    z-index: 1;
    @media only screen and (max-width: $r-768 - 1) {
      position: relative;
      top: 0;
      margin-bottom: 122px; }
    img {
      max-height: 573px;
      max-width: 30%;
      object-fit: contain;
      @media only screen and (max-width: $r-768 - 1) {
        max-width: 85%; } }
    .vectors {
      position: absolute;
      bottom: -78px;
      left: 112px;
      @media only screen and (max-width: $r-1200) {
        left: 30px; }
      @media only screen and (max-width: $r-768 - 1) {
        left: 63px; }
      svg {
        @media only screen and (max-width: $r-768 - 1) {
          max-width: 271px; }
        g {
          &:first-of-type {
            path, rect {
              animation: movedelement 8s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement 6s infinite linear; } } } } }
    &:before {
      position: absolute;
      content: '';
      bottom: -58px;
      left: 0;
      width: 37%;
      height: 305px;
      background: $blue-dark;
      z-index: -1;
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%;
        height: 171px;
        bottom: -32px; } } }
  .breadcrumbs {
    margin-bottom: 17px;
    padding: 0;
    ul {
      display: flex;
      li {
        position: relative;
        padding: 0 12px;
        &:first-child {
          padding-right: 0; }
        &:last-of-type {
          &:before {
            display: none; } }
        &:before {
          position: absolute;
          content: '/';
          left: -5px;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: $orange; }
        a {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: $orange;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 18px;
            line-height: 21px; } } } } }
  .text {
    .title-secondary {
      padding-left: 0;
      margin-bottom: 20px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 45px; }
      &:before {
        display: none; } }
    p {
      text-align: right;
      font-size: 21px;
      line-height: 1.5;
      color: $blue-dark;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 16px; }
      &:last-of-type {
        margin-bottom: 45px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 35px; } } } }
  form {
    display: flex;
    flex-wrap: wrap;
    direction: rtl;
    .wpcf7-response-output {
      max-width: 618px;
      width: 100%;
      margin: 30px 0 0 0;
      font-size: 12px;
      border-color: $orange;
      text-align: right;
      color: $blue-dark; }
    .wpcf7-not-valid-tip {
      font-size: 14px;
      position: absolute;
      right: 0; }
    label {
      width: 100%;
      max-width: 300px;
      margin: 0 0 20px 18px;
      @media only screen and (max-width: $r-1200) {
        max-width: 45%; }
      @media only screen and (max-width: $r-992) {
        max-width: 250px; }
      @media only screen and (max-width: $r-768 - 1) {
        max-width: 100%;
        margin-left: 0; }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: right;
        padding-right: 15px;
        color: $blue-dark;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 15px;
          line-height: 17px;
          margin-bottom: 2px; } }
      input,
      select,
      textarea {
        width: 100%;
        height: 48px;
        background-color: white;
        border: 1px solid #ECEBED;
        box-shadow: 0 7px 34px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        margin-top: 10px;
        padding: 0 15px;
        outline: none;
        appearance: none;
        resize: none;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: $blue-dark; }
      select {
        background-image: url("../img/icon-select.svg");
        background-position: 5%;
        background-repeat: no-repeat; }
      textarea {
        min-height: 161px;
        padding-top: 15px; } }
    .wrapper {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: $r-768 - 1) {
        flex-direction: column; }
      .item {
        width: 100%;
        max-width: 48%;
        @media only screen and (max-width: $r-768 - 1) {
          max-width: 100%; }
        label {
          width: 100%;
          max-width: 100%;
          margin-left: 0; } } }
    .btn-submit {
      width: 100%;
      max-width: 618px;
      text-align: left;
      margin-top: 17px;
      position: relative;
      .wpcf7-spinner {
        position: absolute;
        left: -15px;
        top: 10px; }
      @media only screen and (max-width: $r-992) {
        max-width: 518px; }
      @media only screen and (max-width: $r-768 - 1) {
        text-align: left;
        margin-top: 25px; }
      input {
        border: 0;
        min-width: 175px;
        height: 48px;
        border-radius: 0;
        @media only screen and (max-width: $r-768 - 1) {
          min-width: 120px; } } } } }

