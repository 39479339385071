.page-id-265 {
  .header-navigation {
    background-color: transparent; }
  .nav-up {
    .header-navigation {
      background-color: transparent; } } }


// Contacts hero

.contacts-hero {
  position: relative;
  padding: 155px 0 95px 0;
  min-height: 610px;
  background-size: cover;
  background-position: bottom;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 185px 0 95px 0; }
  //&.add-closed
  //  margin-top: 49px
  .text {
    padding-top: 125px;
    text-align: right;
    .title-main {
      font-size: 52px;
      line-height: 62px;
      margin-bottom: 9px;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 40px;
        line-height: 47px; } }
    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 49px;
      color: white;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 22px;
        line-height: 32px; } }
    p {
      max-width: 640px;
      font-size: 17px; }
    .btn-orange {
      margin-bottom: 55px; } }
  .image {
    position: relative;
    .vectors-left {
      position: absolute;
      bottom: -51px;
      left: -131px;
      z-index: 1;
      svg {
        g {
          &:first-of-type {
            path, rect {
              animation: rotatedelement 15s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement 6s infinite linear; } }
          &:nth-of-type(3) {
            path, rect {
              animation: movedelement2 7s infinite linear; } }
          &:nth-of-type(4) {
            path, rect {
              animation: movedelement2 3s infinite linear; } }
          &:nth-of-type(5) {
            path, rect {
              animation: movedelement2 9s infinite linear; } }
          &:nth-of-type(6) {
            path, rect {
              animation: rotatedelement2 10s infinite linear; } } } } } } }

// Contacts info

.contacts-info {
  padding: 108px 0 225px 0;
  margin-top: -1px;
  background-color: white;
  position: relative;
  z-index: 1;
  &:before {
    position: absolute;
    content: '';
    top: 108px;
    left: 0;
    width: 100%;
    height: calc(100% - 108px);
    clip-path: polygon(0 0, 100% 14%, 100% 100%, 0 86%);
    background-color: #F6F6F8;
    z-index: -1; }
  .vectors-right {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    svg {
      g {
        &:first-of-type {
          path, rect {
            animation: rotatedelement 15s infinite linear; } }
        &:nth-of-type(2) {
          path, rect {
            animation: movedelement 6s infinite linear; } }
        &:nth-of-type(3) {
          path, rect {
            animation: movedelement2 7s infinite linear; } }
        &:nth-of-type(4) {
          path, rect {
            animation: movedelement2 3s infinite linear; } }
        &:nth-of-type(5) {
          path, rect {
            animation: movedelement2 9s infinite linear; } }
        &:nth-of-type(6) {
          path, rect {
            animation: rotatedelement2 10s infinite linear; } } } } }
  .breadcrumbs {
    margin-bottom: 8px;
    padding: 0;
    background-color: transparent;
    ul {
      display: flex;
      li {
        position: relative;
        padding: 0 12px;
        &:first-child {
          padding-right: 0; }
        &:last-of-type {
          &:before {
            display: none; } }
        &:before {
          position: absolute;
          content: '/';
          left: -5px;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: $orange; }
        a {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: $orange;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 18px;
            line-height: 21px; } } } } }
  .text {
    text-align: right;
    margin-bottom: 195px;
    .title-secondary {
      padding-left: 0;
      &:before {
        display: none; } } }
  .wrapper {
    h3 {
      max-width: 540px;
      font-style: normal;
      font-weight: bold;
      font-size: 29px;
      line-height: 34px;
      text-align: right;
      margin-bottom: 37px;
      color: $blue-dark; }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      text-align: right;
      margin-bottom: 19px;
      color: $blue-dark;
      b {
        font-weight: 600; } }
    .contacts-list {
      text-align: right;
      margin-bottom: 54px;
      ul {
        li {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 28px;
          color: $blue-dark;
          a {
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 24px;
            color: $blue-dark; }
          b {
            font-weight: bold;
            margin-left: 10px; }
          img {
            width: 20px;
            margin-left: 27px; } } } } }
  .image {
    position: relative;
    z-index: 1;
    img {
      max-width: 440px;
      filter: drop-shadow(5px 10px 25px rgba(233, 187, 145, 0.4)); }
    .vectors {
      position: absolute;
      left: -44px;
      bottom: 52px;
      svg {
        g {
          &:first-of-type {
            path, rect {
              animation: rotatedelement 15s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement 6s infinite linear; } }
          &:nth-of-type(3) {
            path, rect {
              animation: movedelement2 7s infinite linear; } } } } }
    .parallax {
      position: absolute;
      top: -200px;
      left: -125px;
      z-index: -1; } } }

// Contacts form full

.contacts-form-full {
  padding: 60px 0 85px 0;
  background-color: white;
  position: relative;
  .form {
    form {
      max-width: 725px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      direction: rtl;
      .wpcf7-response-output {
        width: 100%;
        margin: 30px 0 0 0;
        font-size: 12px;
        border-color: $orange;
        text-align: right;
        color: $blue-dark; }
      .wpcf7-not-valid-tip {
        font-size: 14px;
        position: absolute;
        right: 0; }
      label {
        width: 100%;
        max-width: 48%;
        margin: 0 0 20px 0;
        @media only screen and (max-width: $r-768 - 1) {
          max-width: 100%;
          margin-left: 0; }
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          text-align: right;
          padding-right: 15px;
          margin-bottom: 0;
          color: $blue-dark;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 15px;
            line-height: 17px;
            margin-bottom: 2px; } }
        input,
        select,
        textarea {
          width: 100%;
          height: 48px;
          background-color: white;
          border: 1px solid #ECEBED;
          box-shadow: 0 7px 34px rgba(0, 0, 0, 0.05);
          border-radius: 6px;
          margin-top: 10px;
          padding: 0 15px;
          outline: none;
          appearance: none;
          resize: none;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          color: $blue-dark; }
        select {
          background-image: url("../img/icon-select.svg");
          background-position: 5%;
          background-repeat: no-repeat; }
        textarea {
          min-height: 161px;
          padding-top: 15px; } }
      .btn-submit {
        width: 100%;
        text-align: left;
        margin-top: 17px;
        position: relative;
        .wpcf7-spinner {
          position: absolute;
          left: -15px;
          top: 10px; }
        @media only screen and (max-width: $r-768 - 1) {
          text-align: left;
          margin-top: 25px; }
        input {
          border: 0;
          min-width: 187px;
          height: 48px;
          border-radius: 0;
          @media only screen and (max-width: $r-768 - 1) {
            min-width: 120px; } } } } }
  .parallax {
    position: absolute;
    left: 0;
    bottom: 200px; }
  .vectors {
    position: absolute;
    right: 0;
    bottom: 0;
    svg {
      g {
        &:first-of-type {
          path, rect {
            animation: rotatedelement 15s infinite linear; } }
        &:nth-of-type(2) {
          path, rect {
            animation: movedelement 6s infinite linear; } }
        &:nth-of-type(3) {
          path, rect {
            animation: movedelement2 7s infinite linear; } }
        &:nth-of-type(4) {
          path, rect {
            animation: movedelement2 3s infinite linear; } }
        &:nth-of-type(5) {
          path, rect {
            animation: movedelement2 9s infinite linear; } }
        &:nth-of-type(6) {
          path, rect {
            animation: movedelement 9s infinite linear; } }
        &:nth-of-type(8) {
          path, rect {
            animation: movedelement2 10s infinite linear; } }
        &:nth-of-type(9) {
          path, rect {
            animation: movedelement 12s infinite linear; } } } } } }

