.page-id-133,
.page-id-305 {
  .header-navigation {
    background-color: transparent; }
  .nav-up {
    .header-navigation {
      background-color: transparent; } } }

// report hero

.report-hero {
  position: relative;
  padding: 155px 0 95px 0;
  min-height: 610px;
  background-size: cover;
  background-position: bottom;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 185px 0 95px 0; }
  //&.add-closed
  //  margin-top: 49px
  .text {
    padding-top: 20px;
    text-align: right;
    .title-main {
      font-size: 52px;
      line-height: 62px;
      margin-bottom: 9px;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 40px;
        line-height: 47px; } }
    h3 {
      max-width: 540px;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 49px;
      margin-bottom: 32px;
      color: white;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 22px;
        line-height: 32px; } }
    .btn-orange {
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 55px; } } }
  .image {
    position: relative;
    .vectors-left {
      position: absolute;
      bottom: 51px;
      left: -131px;
      z-index: 1;
      svg {
        g {
          &:first-of-type {
            path, rect {
              animation: rotatedelement 15s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement 6s infinite linear; } }
          &:nth-of-type(3) {
            path, rect {
              animation: movedelement2 7s infinite linear; } }
          &:nth-of-type(4) {
            path, rect {
              animation: movedelement2 3s infinite linear; } }
          &:nth-of-type(5) {
            path, rect {
              animation: rotatedelement 9s infinite linear; } }
          &:nth-of-type(6) {
            path, rect {
              animation: rotatedelement2 10s infinite linear; } }
          &:nth-of-type(7) {
            path, rect {
              animation: rotatedelement 13s infinite linear; } }
          &:nth-of-type(8) {
            path, rect {
              animation: rotatedelement2 13s infinite linear; } }
          &:nth-of-type(9) {
            path, rect {
              animation: movedelement 8s infinite linear; } }
          &:nth-of-type(10) {
            path, rect {
              animation: movedelement2 10s infinite linear; } } } } } } }

// Report video

.report-video {
  background-color: white;
  padding: 90px 0 150px 0;
  .text {
    text-align: right;
    margin-bottom: 30px;
    .title-secondary {
      max-width: 855px;
      margin-bottom: 17px;
      &:before {
        top: 30px; } }
    p {
      max-width: 740px;
      font-size: 26px;
      line-height: 36px;
      color: $blue-dark; } }
  .wrapper {
    align-items: center;
    .list {
      padding: 90px 0 50px 0;
      position: relative;
      z-index: 1;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        right: -65px;
        width: 150%;
        height: 100%;
        background-color: $blue-dark;
        z-index: -1; }
      ul {
        text-align: right;
        margin-bottom: 40px;
        li {
          display: flex;
          align-items: center;
          margin-bottom: 28px;
          font-style: normal;
          font-weight: normal;
          font-size: 23px;
          line-height: 27px;
          color: white;
          .icon {
            width: 38px;
            text-align: center;
            margin-left: 22px; } } }
      p {
        max-width: 320px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        text-align: right;
        color: white; } }
    .video {
      position: relative;
      z-index: 1;
      iframe {
        width: 100%;
        min-height: 350px; }
      .parallax-left {
        position: absolute;
        left: -70px;
        top: -60px;
        z-index: -1; }
      .parallax-right {
        position: absolute;
        right: -155px;
        bottom: -30px;
        z-index: -1; }
      .vector {
        position: absolute;
        right: -110px;
        bottom: -125px;
        svg {
          g {
            &:first-of-type {
              path, rect {
                animation: rotatedelement 15s infinite linear; } }
            &:nth-of-type(2) {
              path, rect {
                animation: movedelement 6s infinite linear; } } } } } } } }

// Report text

.report-text {
  background-color: white;
  padding: 150px 0;
  position: relative;
  z-index: 1;
  .text {
    text-align: right;
    .title-secondary {
      font-size: 39px;
      line-height: 46px;
      margin-bottom: 15px;
      &:before {
        top: 25px; } }
    p {
      max-width: 460px;
      font-size: 24px;
      line-height: 36px;
      color: $blue-dark; }
    .check {
      ul {
        li {
          font-size: 24px;
          line-height: 36px;
          color: $blue-dark;
          &:before {
            top: 12px;
            background-image: url("../img/icon-check-yelloow.svg"); } } } } }
  &.first {
    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      height: 428px;
      width: 33%;
      background-color: $blue-dark;
      z-index: -1; }
    .text {
      padding-left: 50px; }
    .image {
      position: relative;
      z-index: 1;
      img {
        width: 100%;
        object-fit: cover; }
      .vector-right {
        position: absolute;
        right: 60px;
        top: -100px;
        z-index: -1;
        svg {
          g {
            &:first-of-type {
              path, rect {
                animation: rotatedelement 15s infinite linear; } }
            &:nth-of-type(2) {
              path, rect {
                animation: movedelement 6s infinite linear; } } } } }
      .vector-left {
        position: absolute;
        left: -35px;
        top: -35px;
        z-index: -1;
        svg {
          g {
            &:first-of-type {
              path, rect {
                animation: rotatedelement 9s infinite linear; } }
            &:nth-of-type(2) {
              path, rect {
                animation: movedelement 6s infinite linear; } } } } }
      .parallax {
        position: absolute;
        left: -89px;
        top: -84px;
        z-index: -1; } }
    .vector-left {
      position: absolute;
      left: 0;
      top: 200px;
      svg {
        g {
          &:first-of-type {
            path, rect {
              animation: movedelement 8s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement2 5s infinite linear; } } } } } }
  &.second {
    background-color: #F6F6F8;
    .row {
      align-items: center; }
    .vector-right {
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1; }
    .text {
      padding-right: 50px; }
    .image {
      position: relative;
      z-index: 1;
      .vector-left {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        svg {
          g {
            &:first-of-type {
              path, rect {
                animation: rotatedelement 9s infinite linear; } }
            &:nth-of-type(2) {
              path, rect {
                animation: movedelement 6s infinite linear; } } } } }
      .vector-right {
        right: 20px;
        top: -100px;
        z-index: -1;
        svg {
          g {
            &:first-of-type {
              path, rect {
                animation: movedelement 8s infinite linear; } }
            &:nth-of-type(2) {
              path, rect {
                animation: movedelement2 5s infinite linear; } } } } }
      .parallax {
        position: absolute;
        right: 72px;
        top: 0;
        z-index: -1; } } }
  &.third {
    padding-top: 265px;
    .row {
      align-items: flex-end; }
    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 145px;
      background-color: $blue-dark;
      height: 428px;
      width: 33%;
      z-index: -1; }
    .text {
      padding-left: 50px; }
    .image {
      position: relative;
      z-index: 1;
      .vector-right {
        position: absolute;
        right: -30px;
        bottom: -30px;
        z-index: -1;
        svg {
          g {
            &:first-of-type {
              path, rect {
                animation: rotatedelement 9s infinite linear; } }
            &:nth-of-type(2) {
              path, rect {
                animation: movedelement 6s infinite linear; } } } } }
      .parallax {
        position: absolute;
        left: -22px;
        top: 50px;
        z-index: -1; } }
    .vector-left {
      position: absolute;
      left: 0;
      top: 55%;
      svg {
        g {
          &:first-of-type {
            path, rect {
              animation: movedelement 8s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement2 5s infinite linear; } } } } } } }
