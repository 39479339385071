// Header

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: transparent;
  z-index: 100;
  .row {
    align-items: center; }
  &.nav-up {
    .header-navigation {
      transition: .3s;
      background-color: $blue-dark; } }
  &.nav-down {
    .header-bar {
      transition: .3s;
      z-index: -1; }
    .header-navigation {
      transition: .3s;
      background-color: $blue-dark; } }
  .add {
    display: flex;
    align-content: center;
    background-color: $yellow;
    padding: 11px 150px 11px 11px;
    position: relative;
    z-index: 3;
    @media only screen and (max-width: $r-768 - 1) {
      padding: 25px 15px 20px 15px; }
    h3 {
      width: 100%;
      text-align: right;
      font-style: normal;
      font-weight: bold;
      font-size: 23px;
      line-height: 27px;
      margin-bottom: 0;
      color: black;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 18px;
        line-height: 21px;
        width: 54%; } }
    .add-close {
      position: absolute;
      right: 11px;
      @media only screen and (max-width: $r-768 - 1) {
        top: 0;
        right: 15px;
        svg {
          width: 12px;
          height: 12px; } } }
    .bg {
      position: absolute;
      left: 68px;
      top: 0;
      height: 100%;
      @media only screen and (max-width: $r-768 - 1) {
        left: 0; }
      svg {
        height: 100%;
        &:first-of-type {
          @media only screen and (max-width: $r-768 - 1) {
            display: none; } }
        &:nth-of-type(2) {
          display: none;
          @media only screen and (max-width: $r-768 - 1) {
            display: block; } } } } }
  &-bar {
    padding: 14px 0;
    background-color: $blue-light;
    margin-top: 0;
    position: relative;
    transition: .3s;
    @media only screen and (max-width: $r-992) {
      display: none; } }
  .socials {
    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0;
      li {
        margin-right: 30px; } } }
  .contacts {
    ul {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0;
      li {
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.005em;
        margin-left: 26px;
        color: white;
        &.header-phone {
          a {
            font-weight: normal; } }
        a {
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 120%;
          letter-spacing: 0.005em;
          color: white; } } } }
  &-navigation {
    background-color: $blue-dark;
    padding: 11px 0;
    margin-top: 0;
    transition: .3s;
    @media only screen and (max-width: $r-768 - 1) {
      padding: 18px 0; }
    &.transparent {
      background-color: transparent; }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media only screen and (max-width: $r-992) {
        justify-content: space-between; }
      .hamburger {
        display: none;
        @media only screen and (max-width: $r-992) {
          display: block; }
        a {
          &.active {
            svg {
              &:first-child {
                display: block; }
              &:nth-of-type(2) {
                display: none; } } }
          svg {
            &:first-child {
              @media only screen and (max-width: $r-992) {
                display: none; } }
            &:nth-of-type(2) {
              display: none;
              @media only screen and (max-width: $r-992) {
                display: block; } } } } }
      .logo {
        margin-left: 43px;
        img {
          width: auto;
          max-height: 73px;
          @media only screen and (max-width: $r-768 - 1) {
            max-height: 50px; } } }
      nav {
        @media only screen and (max-width: $r-992) {
          display: none; }
        ul {
          display: flex;
          margin-bottom: 0;
          li {
            margin: 0 14px;
            @media only screen and (max-width: $r-992) {
              margin: 0; }
            a {
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 18px;
              padding: 0 13px 20px 13px;
              transition: .3s;
              color: white;
              &:hover {
                text-decoration: none;
                transition: .3s;
                color: orange; } }
            &.active {
              &.open-sub-second,
              &.open-sub-first {
                a {
                  &:before {
                    transition: .3s;
                    left: 0;
                    width: 100%; } } }
              .sub-second,
              .sub-first {
                display: flex;
                @media only screen and (max-width: $r-992) {
                  display: none; } } }
            &.open-sub-second,
            &.open-sub-first {
              @media only screen and (max-width: $r-992) {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: relative; }
              a {
                position: relative;
                @media only screen and (max-width: $r-992) {
                  padding-bottom: 0;
                  &:before {
                    bottom: 0!important; } }
                &:before {
                  position: absolute;
                  content: '';
                  bottom: 16px;
                  left: 50%;
                  width: 0;
                  height: 2px;
                  transition: .3s;
                  background-color: $orange; } }
              @media only screen and (max-width: $r-992) {
                padding-right: 67px;
                &:after {
                  position: absolute;
                  content: '';
                  top: 5px;
                  right: 15px;
                  width: 21px;
                  height: 10px;
                  background-image: url("../img/arrow-link-mob.svg");
                  background-size: cover; } }
              ul {
                li {
                  a {
                    &:before {
                      display: none; } } } } }
            &.open-sub-second {
              @media only screen and (max-width: $r-992) {
                &:hover {
                  .sub-second {
                    display: block; } } } }
            &.open-sub-first {
              @media only screen and (max-width: $r-992) {
                &:hover {
                  .sub-first {
                    display: block; } } } }
            .sub-first {
              flex-wrap: wrap;
              padding: 0!important;
              &-flex {
                padding: 35px 54px 0 45px;
                display: flex;
                @media only screen and (max-width: $r-992) {
                  flex-direction: column;
                  padding: 25px 15px; } }
              .item {
                width: 100%;
                min-width: 175px;
                max-width: 175px;
                @media only screen and (max-width: $r-992) {
                  min-width: 100%;
                  max-width: 100%; }
                &:first-of-type {
                  margin-left: 57px;
                  @media only screen and (max-width: $r-992) {
                    margin: 0 0 25px 0; } }
                h3 {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 21px;
                  margin-bottom: 10px;
                  text-align: right;
                  color: $blue-dark; }
                ul {
                  margin-top: 0; } }
              .image {
                max-width: 375px!important;
                min-width: 375px!important; }
              &-bottom {
                width: 100%;
                background-color: $blue-dark;
                padding: 30px 35px;
                display: flex;
                justify-content: space-between;
                @media only screen and (max-width: $r-992) {
                  flex-direction: column;
                  padding: 35px; }
                .item {
                  width: 100%;
                  max-width: 30%;
                  margin-left: 0;
                  @media only screen and (max-width: $r-992) {
                    max-width: 100%;
                    margin-bottom: 30px; }
                  &:first-of-type {
                    margin-left: 0; }
                  .top {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    .price {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: #FECE00;
                      width: 47px;
                      padding: 1px 5px;
                      margin-left: 15px;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 10px;
                      line-height: 15px;
                      text-transform: uppercase;
                      color: $blue-dark;
                      @media only screen and (max-width: $r-992) {
                        order: 1; } }
                    h3 {
                      font-style: normal;
                      font-weight: bold;
                      font-size: 16px;
                      line-height: 19px;
                      margin: 0 0 0 8px;
                      color: #F1F6FC;
                      @media only screen and (max-width: $r-992) {
                        order: 3;
                        margin: 0 12px 0 0; } }
                    svg {
                      @media only screen and (max-width: $r-992) {
                        order: 2; } } }
                  .bottom {
                    display: flex;
                    align-items: center;
                    @media only screen and (max-width: $r-992) {
                      background-color: transparent;
                      padding: 0;
                      min-height: auto; }
                    .icon {
                      min-width: 38px;
                      width: 38px;
                      margin-left: 15px; }
                    p {
                      font-style: normal;
                      font-weight: 300;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      margin-bottom: 0;
                      color: white; } } } } }
            .sub-second,
            .sub-first {
              display: none;
              position: absolute;
              top: 65px;
              background: white;
              box-shadow: 4px 10px 20px rgba(45, 45, 45, 0.05);
              padding: 35px 54px 0 45px;
              @media only screen and (max-width: $r-992) {
                position: relative;
                top: 20px;
                left: 67px;
                width: calc(100% + 67px); }
              ul {
                flex-direction: column;
                width: 100%;
                min-width: 175px;
                max-width: 175px;
                border-top: 1px solid $blue-dark;
                margin-top: 30px;
                padding-top: 20px;
                @media only screen and (max-width: $r-992) {
                  margin-top: 0;
                  padding: 20px 0 30px 0; }
                li {
                  margin: 0 0 17px 0;
                  a {
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: right;
                    padding: 0;
                    color: $blue-dark;
                    &:hover {
                      color: $orange; }
                    .icon {
                      width: 20px;
                      text-align: center;
                      margin-left: 20px; } } } }
              .image {
                width: 100%;
                max-width: 330px;
                min-width: 330px;
                @media only screen and (max-width: $r-992) {
                  display: none; } } } } } } }
    .btn-join {
      @media only screen and (max-width: $r-992) {
        display: none; }
      a {
        min-width: 142px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        text-align: right;
        text-transform: uppercase;
        border: 1.5px solid #F48A28;
        color: white; } }
    .nav-mobile {
      display: none;
      &.active {
        position: absolute;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        left: 0;
        top: 67px;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        padding-bottom: 171px;
        z-index: 2; }
      nav {
        display: block;
        background-color: $blue-dark;
        padding-top: 30px;
        ul {
          flex-direction: column;
          align-items: flex-start;
          li {
            margin-bottom: 28px;
            a {
              font-size: 18px;
              line-height: 21px; } } } }
      .bottom {
        min-height: 130px;
        background-color: $blue-light;
        padding: 23px 15px;
        .socials {
          margin-bottom: 19px;
          ul {
            justify-content: flex-start;
            li {
              margin: 0 0 0 30px; } } }
        .contacts {
          ul {
            flex-direction: column;
            margin-left: 0;
            text-align: right;
            li {
              margin-bottom: 18px; } } } } } } }

