.page-id-191 {
  .header-navigation {
    background-color: transparent; }
  .nav-up {
    .header-navigation {
      background-color: transparent; } } }

// Blog hero

.blog-hero {
  display: flex;
  justify-content: center;
  min-height: 270px;
  padding-top: 155px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media only screen and (max-width: $r-768 - 1) {
    min-height: 120px;
    padding-top: 185px; }
  //&.add-closed
  //  margin-top: 49px
  .vectors {
    max-width: 100%;
    position: absolute;
    top: 95px;
    z-index: 1;
    @media only screen and (max-width: $r-768 - 1) {
      top: initial; }
    svg {
      &:first-child {
        @media only screen and (max-width: $r-768 - 1) {
          display: none; } }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: $r-768 - 1) {
          display: block; } }
      @media only screen and (max-width: $r-1200) {
        max-width: 100%; }
      g {
        &:first-of-type {
          path, rect {
            animation: movedelement 8s infinite linear; } }
        &:nth-of-type(2) {
          path, rect {
            animation: movedelement 6s infinite linear; } }
        &:nth-of-type(3) {
          path, rect {
            animation: movedelement2 7s infinite linear; } }
        &:nth-of-type(4) {
          path, rect {
            animation: movedelement2 3s infinite linear; } }
        &:nth-of-type(5) {
          path, rect {
            animation: movedelement 9s infinite linear; } }
        &:nth-of-type(6) {
          path, rect {
            animation: rotatedelement2 10s infinite linear; } }
        &:nth-of-type(7) {
          path, rect {
            animation: movedelement2 13s infinite linear; } }
        &:nth-of-type(8) {
          path, rect {
            animation: movedelement 9s infinite linear; } }
        &:nth-of-type(9) {
          path, rect {
            animation: movedelement 8s infinite linear; } }
        &:nth-of-type(10) {
          path, rect {
            animation: movedelement2 10s infinite linear; } }
        &:nth-of-type(11) {
          path, rect {
            animation: rotatedelement 12s infinite linear; } }
        &:nth-of-type(12) {
          path, rect {
            animation: movedelement 7s infinite linear; } }
        &:nth-of-type(13) {
          path, rect {
            animation: movedelement2 10s infinite linear; } }
        &:nth-of-type(14) {
          path, rect {
            animation: movedelement2 6s infinite linear; } } } } } }

// Blog list

.blog-list {
  background-color: white;
  padding-bottom: 120px;
  @media only screen and (max-width: $r-768 - 1) {
    margin-top: -1px; }
  .text {
    margin-bottom: 72px;
    text-align: right;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 40px; } }
  .title-secondary {
    padding-left: 0;
    &:before {
      display: none; } }
  .wrapper {
    .item {
      display: flex;
      align-items: center;
      background-color: white;
      box-shadow: 0 0 32.85px rgba(190, 196, 250, 0.3);
      padding: 50px;
      margin-bottom: 45px;
      @media only screen and (max-width: $r-1200) {
        padding: 40px 30px; }
      @media only screen and (max-width: $r-768 - 1) {
        flex-direction: column;
        margin-bottom: 25px; }
      &:nth-of-type(odd) {
        .image {
          svg {
            &:first-child {
              display: block; }
            &:nth-of-type(2) {
              display: none; } } } }
      &:nth-of-type(even) {
        .image {
          @media only screen and (max-width: $r-768 - 1) {
            order: 1; }
          svg {
            &:first-child {
              display: none; }
            &:nth-of-type(2) {
              display: block; } } }
        .text {
          order: 2; } }
      .text {
        width: calc(100% - 350px);
        padding-right: 75px;
        margin-bottom: 0;
        @media only screen and (max-width: $r-992) {
          padding-right: 30px; }
        @media only screen and (max-width: $r-768 - 1) {
          width: 100%;
          padding-right: 0; }
        h3 {
          margin-bottom: 20px;
          @media only screen and (max-width: $r-768 - 1) {
            margin-bottom: 15px; }
          a {
            font-style: normal;
            font-weight: bold;
            font-size: 23px;
            line-height: 27px;
            color: $blue-dark;
            @media only screen and (max-width: $r-768 - 1) {
              font-size: 21px;
              line-height: 24px; }
            &:hover {
              text-decoration: none; } } }
        p {
          max-width: 575px;
          color: $blue-dark;
          &:last-of-type {
            margin-bottom: 25px; } }
        .date {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 1;
          color: $blue-dark;
          svg {
            margin-left: 20px; } } }
      .image {
        width: 100%;
        max-width: 350px;
        position: relative;
        z-index: 1;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 20px; }
        .vector {
          svg {
            position: absolute;
            z-index: -1;
            &:first-child {
              right: -18px;
              top: -18px;
              @media only screen and (max-width: $r-768 - 1) {
                right: -12px;
                top: -12px; } }
            &:nth-of-type(2) {
              left: -18px;
              bottom: -18px;
              @media only screen and (max-width: $r-768 - 1) {
                left: -12px;
                bottom: -12px; } } } } } } } }

// Blog single

.blog-single {
  padding: 155px 0 150px 0;
  background-color: white;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 185px 0 150px 0; }
  .top {
    display: flex;
    margin-bottom: 60px;
    @media only screen and (max-width: $r-992) {
      flex-direction: column; }
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 30px; }
    .image {
      width: 100%;
      max-width: 335px;
      height: fit-content;
      position: relative;
      z-index: 1;
      @media only screen and (max-width: $r-768 - 1) {
        padding-right: 15px;
        margin-bottom: 35px; }
      .vector {
        position: absolute;
        right: -18px;
        bottom: -18px;
        z-index: -1;
        @media only screen and (max-width: $r-768 - 1) {
          right: 0;
          bottom: -13px; } } }
    .info {
      width: calc(100% - 335px);
      padding-right: 35px;
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%;
        padding-right: 0; }
      &.show-mobile {
        display: none;
        @media only screen and (max-width: $r-768 - 1) {
          display: block;
          .navigation {
            display: flex;
            justify-content: space-between;
            margin: 30px 0; } } }
      .navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 50px;
        &.show-desctop {
          display: flex;
          @media only screen and (max-width: $r-768 - 1) {
            display: none; } }
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
          text-transform: uppercase;
          margin: 0 20px;
          color: #898799;
          @media only screen and (max-width: $r-992) {
            margin: 0 10px; }
          @media only screen and (max-width: $r-768 - 1) {
            display: flex;
            flex-wrap: nowrap;
            font-size: 12px;
            margin: 0;
            white-space: nowrap; }
          svg {
            margin: 0 10px;
            @media only screen and (max-width: $r-768 - 1) {
              margin: 0 5px; } }
          &.next {
            font-weight: bold;
            color: $orange; }
          &:last-of-type {
            margin-left: 0;
            svg {
              margin-left: 0; } }
          &:first-child {
            @media only screen and (max-width: $r-768 - 1) {
              margin-right: 0;
              svg {
                margin-right: 0; } } } } }
      .text {
        .title {
          display: flex;
          margin-bottom: 25px;
          @media only screen and (max-width: $r-992) {
            flex-direction: column; }
          @media only screen and (max-width: $r-768 - 1) {
            margin-bottom: 20px; }
          .title-secondary {
            max-width: 415px;
            font-size: 34px;
            line-height: 40px;
            padding-left: 0;
            margin-left: 30px;
            @media only screen and (max-width: $r-992) {
              order: 2; }
            @media only screen and (max-width: $r-768 - 1) {
              font-size: 27px;
              line-height: 32px; }
            &:before {
              display: none; } }
          .share {
            @media only screen and (max-width: $r-992) {
              order: 1;
              margin-bottom: 30px; }
            .btn-orange {
              min-width: 122px;
              height: 50px;
              font-size: 15px;
              padding: 0 40px 0 30px;
              transition: .3s;
              position: relative;
              cursor: pointer;
              &:before {
                position: absolute;
                content: '';
                top: 14px;
                right: 19px;
                background-image: url("../img/icon-share.svg");
                background-size: contain;
                background-repeat: no-repeat;
                width: 21px;
                height: 21px; }
              &:hover {
                background-color: $orange;
                text-decoration: none;
                background-position: 95%;
                transition: .3s;
                a {
                  display: inline-flex; }
                .a2a_dd {
                  margin-left: 20px; } }
              a {
                display: none;
                padding: 0 7px;
                font-weight: 500;
                color: white; }
              .a2a_dd {
                display: block;
                transition: .3s;
                &:hover {
                  text-decoration: none; } } }
            .a2a_kit {
              display: flex;
              align-items: center;
              flex-direction: row-reverse; } } }
        .date {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 1;
          color: $blue-dark;
          svg {
            margin-left: 20px; } } } } }
  .wrapper {
    max-width: 990px;
    margin: 0 auto 100px auto;
    @media only screen and (max-width: $r-768 - 1) {
      margin: 0 -15px 50px -15px; } }
  .content {
    text-align: right;
    max-width: 560px;
    @media only screen and (max-width: $r-992) {
      max-width: 100%;
      padding-bottom: 100px; }
    p {
      color: $blue-dark; }
    ul {
      li {
        position: relative;
        padding-right: 28px;
        margin-bottom: 14px;
        color: $blue-dark;
        &:before {
          position: absolute;
          content: '';
          right: 0;
          top: 6px;
          width: 9px;
          height: 9px;
          background-image: url("../img/icon-content-list.svg"); } } }
    h1, h2, h3, h4, h5, h6 {
      color: $blue-dark;
      b, strong {
        color: $orange; } }
    img,
    iframe,
    video {
      max-width: 470px;
      height: auto;
      margin-bottom: 16px;
      @media only screen and (max-width: $r-768 - 1) {
        max-width: 100%; } }
    .content-banner {
      background-color: #f6f6f8;
      background-image: url("../img/vector-content-banner.svg");
      background-position: 8px 3px;
      background-repeat: no-repeat;
      padding: 26px 28px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      &:hover {
        text-decoration: none; }
      @media only screen and (max-width: $r-768 - 1) {
        flex-direction: column;
        padding: 20px 20px 30px 20px;
        background-position: 85% 85%; }
      .btn-orange {
        max-width: 193px;
        height: 50px;
        margin-left: 13px;
        text-align: center;
        @media only screen and (max-width: $r-768 - 1) {
          order: 3;
          font-size: 15px;
          margin-left: 0; } }
      h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        margin: 0 0 0 13px;
        color: $blue-dark;
        @media only screen and (max-width: $r-768 - 1) {
          order: 2;
          font-size: 15px;
          margin: 0 0 6px 0; } }
      .more {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        text-decoration-line: underline;
        color: $blue-dark;
        @media only screen and (max-width: $r-768 - 1) {
          order: 1;
          font-size: 15px;
          margin-bottom: 6px; } }
      .vector {
        position: absolute;
        left: 8px;
        top: 3px;
        z-index: -1; } } }

  .sidebar {
    .title {
      text-align: right;
      h3 {
        font-size: 28px;
        line-height: 33px;
        text-transform: uppercase;
        margin-bottom: 45px;
        padding: 14px 0 0 0;
        color: $blue-dark;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 25px;
          line-height: 29px;
          margin-bottom: 30px; }
        &:before {
          width: 73px;
          top: 0;
          right: 0;
          left: initial; } } }
    .interested {
      display: flex;
      flex-direction: column;
      .item {
        margin-bottom: 75px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 70px; }
        &:first-child {
          order: 1; }
        &:nth-of-type(2) {
          order: 2; }
        &:nth-of-type(3) {
          order: 4; }
        .image {
          width: 100%;
          margin-bottom: 22px;
          @media only screen and (max-width: $r-768 - 1) {
            margin-bottom: 25px; } }
        .date {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 1;
          margin-bottom: 2px;
          color: $blue-dark;
          svg {
            margin-left: 20px; } }
        .title {
          h3 {
            margin-bottom: 0; }
          a {
            font-style: normal;
            font-weight: bold;
            font-size: 23px;
            line-height: 30px;
            color: $blue-dark;
            @media only screen and (max-width: $r-768 - 1) {
              font-size: 21px;
              line-height: 27px; } } } } }
    .sidebar-banner {
      order: 3;
      min-height: 300px;
      background-color: #f6f6f8;
      background-image: url("../img/vector-sidebar-banner.svg");
      background-position: 70% 88%;
      background-repeat: no-repeat;
      margin-bottom: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      z-index: 1;
      &:hover {
        text-decoration: none; }
      @media only screen and (max-width: $r-768 - 1) {
        flex-direction: column;
        min-height: 270px; }
      .btn-orange {
        max-width: 193px;
        height: 50px;
        margin-bottom: 21px;
        @media only screen and (max-width: $r-768 - 1) {
          order: 3;
          font-size: 15px; } }
      h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 23px;
        line-height: 30px;
        margin-bottom: 14px;
        color: $blue-dark;
        @media only screen and (max-width: $r-768 - 1) {
          order: 2;
          font-size: 15px; } }
      .more {
        font-style: normal;
        font-weight: bold;
        font-size: 23px;
        line-height: 30px;
        text-align: center;
        text-decoration-line: underline;
        color: $blue-dark;
        @media only screen and (max-width: $r-768 - 1) {
          order: 1;
          font-size: 15px;
          margin-bottom: 6px; } }
      .vector {
        position: absolute;
        right: 20px;
        bottom: 25px;
        z-index: -1; } } }
  .related-block {
    padding-bottom: 0; } }

.related-block {
  background-color: white;
  padding-bottom: 130px;
  .related {
    position: relative;
    z-index: 1;
    .flex {
      display: flex;
      align-items: center;
      position: relative;
      .arrows {
        position: absolute;
        left: 0;
        top: 18px;
        margin-top: 0; } }
    .title-secondary {
      width: 100%;
      justify-content: center;
      text-align: center;
      padding: 0 0 14px 0;
      margin-bottom: 10px;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 27px;
        line-height: 32px;
        margin-bottom: 0; }
      &:before {
        bottom: 0;
        left: initial;
        @media only screen and (max-width: $r-768 - 1) {
          width: 112px; } } }
    .item {
      background: white;
      height: 100%;
      box-shadow: 0 0 32.85px rgba(190, 196, 250, 0.3);
      padding: 30px;
      text-align: right;
      @media only screen and (max-width: $r-992) {
        padding: 23px; }
      @media only screen and (max-width: $r-768 - 1) {
        display: flex;
        flex-direction: column; }
      .image {
        margin-bottom: 30px; }
      .date {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 1;
        margin-bottom: 18px;
        color: $blue-dark;
        svg {
          margin-left: 20px; } }
      .title {
        margin-bottom: 20px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 10px; }
        h3 {
          a {
            font-style: normal;
            font-weight: bold;
            font-size: 23px;
            line-height: 30px;
            color: $blue-dark;
            @media only screen and (max-width: $r-768 - 1) {
              font-size: 21px;
              line-height: 24px; } } } }
      .text {
        p {
          color: $blue-dark;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 13px; } } }
      .more {
        a {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          color: $orange;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 17px; } } } }
    .btn-more {
      text-align: center;
      padding-top: 40px;
      .btn-orange {
        text-transform: uppercase; } }
    .vector-right {
      position: absolute;
      right: -110px;
      top: 255px;
      z-index: -1;
      @media only screen and (max-width: $r-768 - 1) {
        display: none; } }
    .vector-left {
      position: absolute;
      left: -106px;
      bottom: 83px;
      z-index: -1;
      @media only screen and (max-width: $r-768 - 1) {
        left: -206px;
        bottom: 175px; } } }
  .slider-related {
    padding: 45px 30px;
    @media only screen and (max-width: $r-768 - 1) {
      padding: 45px 15px; } }
  .arrows {
    display: flex;
    justify-content: center;
    margin-top: 78px;
    &.show-desctop {
      display: flex;
      flex-wrap: wrap;
      @media only screen and (max-width: $r-768 - 1) {
        display: none; } }
    &.show-mobile {
      display: none;
      @media only screen and (max-width: $r-768 - 1) {
        display: flex;
        width: 100%;
        max-width: 100%; } }
    .swiper-button-next,
    .swiper-button-prev {
      background-image: none;
      position: initial;
      margin: 0 12px;
      width: auto;
      height: auto;
      outline: none; } } }



