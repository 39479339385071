.page-id-353,
.single-knowledgebase,
.archive {
  .header-navigation {
    background-color: transparent; }
  .nav-up {
    .header-navigation {
      background-color: transparent; } } }

// Blog hero

.knowledgebase-hero {
  display: flex;
  justify-content: center;
  padding-top: 155px;
  min-height: 315px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  @media only screen and (max-width: $r-768 - 1) {
    min-height: 95px;
    padding: 185px 0 30px 0; }
  //&.add-closed
  //  margin-top: 49px
  .vectors {
    max-width: 100%;
    position: absolute;
    top: 95px;
    @media only screen and (max-width: $r-768 - 1) {
      top: initial; }
    svg {
      &:first-child {
        @media only screen and (max-width: $r-768 - 1) {
          display: none; } }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: $r-768 - 1) {
          display: block; } }
      @media only screen and (max-width: $r-1200) {
        max-width: 100%; }
      g {
        &:first-of-type {
          path, rect {
            animation: movedelement 8s infinite linear; } }
        &:nth-of-type(2) {
          path, rect {
            animation: movedelement 6s infinite linear; } }
        &:nth-of-type(3) {
          path, rect {
            animation: movedelement2 7s infinite linear; } }
        &:nth-of-type(4) {
          path, rect {
            animation: movedelement2 3s infinite linear; } }
        &:nth-of-type(5) {
          path, rect {
            animation: movedelement 9s infinite linear; } }
        &:nth-of-type(6) {
          path, rect {
            animation: rotatedelement2 10s infinite linear; } }
        &:nth-of-type(7) {
          path, rect {
            animation: movedelement2 13s infinite linear; } }
        &:nth-of-type(8) {
          path, rect {
            animation: movedelement 9s infinite linear; } }
        &:nth-of-type(9) {
          path, rect {
            animation: movedelement 8s infinite linear; } }
        &:nth-of-type(10) {
          path, rect {
            animation: movedelement2 10s infinite linear; } }
        &:nth-of-type(11) {
          path, rect {
            animation: movedelement2 12s infinite linear; } } } } }
  .text {
    position: relative;
    z-index: 1;
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 11px;
      text-align: center;
      color: white;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 15px;
        line-height: 17px; } }
    form {
      display: flex;
      width: 100%;
      max-width: 475px;
      margin: 0 auto 15px auto;
      text-align: center;
      border: 1px solid #ECEBED;
      background-color: white;
      @media only screen and (max-width: $r-768 - 1) {
        max-width: 250px;
        margin-bottom: 7px; }
      label {
        order: 2; }
      .is-search-submit {
        display: none;
        order: 1;
        height: 47px;
        .is-search-icon {
          width: 50px;
          height: 100%;
          display: flex!important;
          align-items: center;
          justify-content: center;
          padding: 0!important;
          border: 0;
          background-color: white;
          background-image: url("../img/icon-search.svg");
          background-position: center;
          background-repeat: no-repeat;
          svg {
            display: none; } } }
      input[type="search"] {
        outline: none;
        appearance: none;
        border: 0!important;
        background-image: url("../img/icon-search.svg") !important;
        background-position: 94%;
        background-repeat: no-repeat;
        width: 100%;
        height: 47px;
        padding: 0 75px 0 15px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px!important;
        line-height: 19px;
        color: rgb(26, 21, 55);
        @media only screen and (max-width: $r-768 - 1) {
          height: 25px;
          font-size: 10px!important;
          background-size: 14px;
          padding: 0 35px 0 0; }
        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: rgba(26, 21, 55, 0.7);
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 10px; } } } }
    .form-additional {
      text-align: center;
      p, a {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.7);
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 8px;
          line-height: 10px;
          margin-bottom: 0; } }
      a {
        text-decoration-line: underline; } } } }

body {
  .is-ajax-search-result {
    max-width: 475px!important;
    width: 100% !important;
    padding: 0!important;
    box-shadow: 5px 10px 25px rgba(190,196,250,.8);
    .is-ajax-search-post {
      &:hover {
        background-color: #f6f6f8; } }
    .is-title {
      a {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
        text-align: center;
        color: $blue-dark; } }
    .is-ajax-result-description {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: $blue-dark; } } }


// Knowledgebase categories

.knowledgebase-categories {
  padding: 80px 0 0 0;
  background-color: white;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 0 0 50px 0; }
  .breadcrumbs {
    display: none;
    @media only screen and (max-width: $r-768 - 1) {
      display: block; } }
  .text {
    margin-bottom: 70px;
    text-align: center;
    @media only screen and (max-width: $r-768 - 1) {
      text-align: right; }
    .title-secondary {
      justify-content: center;
      padding-bottom: 7px;
      padding-left: 0;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 40px;
        line-height: 47px;
        justify-content: flex-start; }
      &:before {
        left: initial;
        bottom: 0;
        @media only screen and (max-width: $r-768 - 1) {
          width: 73px; } } } }
  .wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    @media only screen and (max-width: $r-768 - 1) {
      justify-content: space-between; }
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 22%;
      margin: 0 1.5%;
      background-color: white;
      box-shadow: 5px 10px 25px rgba(190, 196, 250, 0.3);
      padding: 50px;
      margin-bottom: 135px;
      transition: .3s;
      @media only screen and (max-width: $r-768 - 1) {
        max-width: 242px;
        width: 100%;
        margin: 0 auto 25px auto;
        padding: 35px 15px 25px 15px; }
      &:hover {
        transition: .3s;
        text-decoration: none;
        box-shadow: 5px 10px 25px rgba(190, 196, 250, 0.8); }
      .icon {
        margin-bottom: 20px;
        img {
          height: 85px; } }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: $blue-dark; } }
    .line {
      width: 840px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 330px;
      @media only screen and (max-width: $r-992) {
        max-width: 80%; }
      @media only screen and (max-width: $r-768 - 1) {
        display: none; }
      &:before {
        position: absolute;
        content: '';
        top: 4px;
        width: 100%;
        height: 2px;
        background-color: #E9E5F6; }
      span {
        width: 30px;
        height: 10px;
        display: flex;
        position: relative;
        z-index: 1;
        &:first-child {
          background-color: $blue-dark; }
        &:nth-of-type(2) {
          background-color: $orange; }
        &:nth-of-type(3) {
          background-color: $yellow; } } } } }

// Knowledgebase guides

.knowledgebase-guides {
  background-color: white;
  padding: 0 0 110px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 50px 0; }
  .text {
    margin-bottom: 70px;
    text-align: center;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 37px; }
    .title-secondary {
      justify-content: center;
      padding-bottom: 7px;
      @media only screen and (max-width: $r-768 - 1) {
        padding: 0 0 0 85px; }
      &:before {
        left: initial;
        bottom: 0;
        @media only screen and (max-width: $r-768 - 1) {
          left: 0;
          top: 16px;
          bottom: initial;
          width: 73px; } } } }
  .show-desctop {
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  .show-mobile {
    display: none;
    @media only screen and (max-width: $r-768 - 1) {
      display: block; } }
  .wrapper {
    .item {
      background-color: white;
      box-shadow: 5px 10px 25px rgba(190, 196, 250, 0.3);
      padding: 17px 35px 10px 20px;
      margin-bottom: 27px;
      @media only screen and (max-width: $r-768 - 1) {
        padding: 30px 20px;
        margin-bottom: 35px; }
      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: right;
          color: $blue-dark;
          @media only screen and (max-width: $r-768 - 1) {
            margin-left: 20px; } }
        a {
          min-width: 46px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          background-color: $orange;
          transition: .3s;
          color: white;
          @media only screen and (max-width: $r-768 - 1) {
            min-width: 38px;
            height: 31px;
            font-size: 14px;
            line-height: 16px; }
          &:hover {
            transition: .3s;
            text-decoration: none;
            background-color: $yellow; } } }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        text-align: right;
        padding-left: 20px;
        color: rgba(0, 0, 0, 0.6);
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 14px; } } }
    .slider-guides {
      .arrows {
        display: flex;
        justify-content: center;
        .swiper-button-next,
        .swiper-button-prev {
          outline: none;
          background-image: none;
          position: initial;
          margin: 0;
          width: auto;
          height: auto;
          @media only screen and (max-width: $r-768 - 1) {
            margin: 0 12px; } } } } } }

// Knowledgebase category

.knowledgebase-category-categories {
  background-color: white;
  padding: 32px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 35px 0 0 0; }
  div[class*="col-"] {
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0; } }
  .wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    &.show-desctop {
      @media only screen and (max-width: $r-768 - 1) {
        display: none; } }
    &.show-mobile {
      display: none;
      @media only screen and (max-width: $r-768 - 1) {
        display: flex; } }
    .slider-categories {

      padding-bottom: 30px;
      .swiper-slide {
        width: 120px!important; }
      .arrows {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 0 15px;
        .swiper-button-next,
        .swiper-button-prev {
          outline: none;
          background-image: none;
          position: initial;
          margin: 0;
          width: auto;
          height: auto; } } }
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 12%;
      margin: 0 1% 40px 1%;
      background-color: white;
      box-shadow: 5px 10px 25px rgba(190, 196, 250, 0.3);
      padding: 25px 15px 15px 15px;
      position: relative;
      transition: .3s;
      @media only screen and (max-width: $r-768 - 1) {
        width: 100%;
        margin: 0;
        padding: 22px 10px; }
      &:hover {
        transition: .3s;
        text-decoration: none;
        box-shadow: 5px 10px 25px rgba(190, 196, 250, 0.8); }
      &:before {
        display: none;
        position: absolute;
        content: '';
        width: 30px;
        height: 10px;
        background-color: $blue-dark;
        bottom: -30px;
        transition: .3s; }
      &.active {
        background-color: $orange;
        box-shadow: none;
        transition: .3s;
        &:before {
          display: block;
          transition: .3s; }
        h3 {
          color: white; } }
      .icon {
        margin-bottom: 10px;
        img {
          height: 50px; } }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 17px;
        text-align: center;
        color: $blue-dark;
        @media only screen and (max-width: $r-768 - 1) {
          min-height: 35px; } } } } }

// Knowledgebase content

.knowledgebase-content {
  background-color: white;
  padding: 60px 0;
  .bar {
    height: 100%;
    position: relative; }
  .sidebar {
    position: sticky;
    position: -webkit-sticky;
    top: 155px;
    text-align: right;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 65px; }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 16px;
      color: $blue-dark;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 27px; } }
    ul {
      width: 100%;
      li {
        width: 100%;
        display: none;
        &.active {
          display: block;
          ul {
            display: block;
            li {
              display: block;
              a {
                &.active {
                  background-color: $orange;
                  padding-right: 30px;
                  font-weight: 600;
                  transition: .3s;
                  color: white; } } } } }
        a {
          display: block;
          align-items: center;
          width: 100%;
          max-width: 260px;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: right;
          margin-bottom: 26px;
          transition: .3s;
          color: $blue-dark;
          &:hover {
            transition: .3s;
            color: $orange; }
          @media only screen and (max-width: $r-768 - 1) {
            max-width: 300px; } }
        ul {
          display: none;
          li {
            a {
              height: 50px;
              max-width: 260px;
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 21px;
              margin-bottom: 10px;
              transition: .3s;
              color: $blue-dark;
              &:hover {
                transition: .3s;
                color: $orange; } } } } } } }
  .content {
    &-breadcrumbs {
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      text-align: right;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 25px; }
      &.show-desctop {
        @media only screen and (max-width: $r-768 - 1) {
          display: none; } }
      &.show-mobile {
        display: none;
        @media only screen and (max-width: $r-768 - 1) {
          display: flex; } }
      a,
      span {
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        text-align: right;
        padding: 0 5px;
        color: $blue-dark; }
      a {
        font-weight: 400;
        &:first-of-type {
          padding-right: 0; } }
      span {
        display: inline-block;
        font-weight: bold; } }
    &-wrapper {
      text-align: right;
      .subcat-name {
        font-style: normal;
        font-weight: bold;
        font-size: 23px;
        line-height: 25px;
        margin: 55px 0;
        color: $blue-dark;
        &:first-of-type {
          margin-top: 0; } }
      .post-name {
        width: 100%;
        display: flex;
        margin-bottom: 21px;
        padding: 10px 44px;
        background-color: rgba(246, 246, 248, 0.6);
        border-top: 1px solid rgba(26, 21, 55, 0.3);
        border-bottom: 1px solid rgba(26, 21, 55, 0.3);
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: right;
        text-decoration-line: underline;
        color: $orange; }

      h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 1.3;
        margin-bottom: 30px;
        color: $orange;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 27px;
          line-height: 1.2;
          margin-bottom: 30px; } }
      h2, h3, h4, h5, h6 {
        font-style: normal;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 16px;
        color: $blue-dark; }
      p,
      ul li,
      ol li {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: $blue-dark;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 16px;
          line-height: 28px; } }
      ul {
        padding-right: 0;
        li {
          position: relative;
          padding-right: 28px;
          &:before {
            position: absolute;
            content: '';
            right: 0;
            top: 11px;
            width: 9px;
            height: 9px;
            background-image: url("../img/icon-know-content-list.svg");
            background-repeat: no-repeat;
            background-size: cover; } } }
      ol {
        padding-right: 20px; }
      ul,
      ol {
        margin-bottom: 40px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 30px; } }
      blockquote {
        background-color: #F6F6F8;
        padding: 24px 98px 15px 160px;
        margin: 50px 0;
        border-right: 7px solid $orange;
        background-image: url("../img/vector-know-content-blockquote.svg");
        background-repeat: no-repeat;
        @media only screen and (max-width: $r-768 - 1) {
          padding: 26px;
          margin: 30px 0;
          border-right: 0;
          position: relative;
          &:before {
            position: absolute;
            content: '';
            width: 7px;
            height: 50%;
            right: 0;
            top: 0;
            background-color: $orange; } }
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 30px;
          color: $blue-dark;
          @media only screen and (max-width: $r-768 - 1) {
            line-height: 28px; } } }
      img {
        filter: drop-shadow(0px 9px 25.85px rgba(117, 117, 133, 0.2));
        margin: 15px 0;
        @media only screen and (max-width: $r-768 - 1) {
          margin: 10px 0; } }
      .video-block {
        .wrapper {
          .item {
            max-width: 460px;
            margin-bottom: 40px;
            @media only screen and (max-width: $r-768 - 1) {
              margin-bottom: 15px; }
            &-title {
              font-style: normal;
              font-weight: bold;
              font-size: 23px;
              line-height: 27px;
              margin-bottom: 20px;
              color: $orange;
              @media only screen and (max-width: $r-768 - 1) {
                margin-bottom: 30px; } }
            &-video {
              max-width: 460px;
              margin-bottom: 15px;
              @media only screen and (max-width: $r-768 - 1) {
                margin-bottom: 20px; }
              iframe {
                width: 100%;
                @media only screen and (max-width: $r-768 - 1) {
                  height: 160px; } } } } }
        .wrapper-two {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .item {
            max-width: 48%;
            @media only screen and (max-width: $r-768 - 1) {
              max-width: 460px; }
            &-title {
              font-size: 22px;
              line-height: 26px;
              @media only screen and (max-width: $r-768 - 1) {
                font-size: 23px;
                line-height: 27px; } }
            &-text {
              p {
                font-size: 16px;
                line-height: 18px; } } } }
        .wrapper-three {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .item {
            max-width: 31%;
            @media only screen and (max-width: $r-768 - 1) {
              max-width: 460px; }
            &-title {
              font-size: 19px;
              line-height: 22px;
              margin-bottom: 15px;
              @media only screen and (max-width: $r-768 - 1) {
                font-size: 23px;
                line-height: 27px;
                margin-bottom: 30px; } }
            &-video {
              margin-bottom: 10px;
              @media only screen and (max-width: $r-768 - 1) {
                margin-bottom: 20px; } }
            &-text {
              p {
                font-size: 13px;
                line-height: 15px; } } } } }
      .file-block {
        margin-bottom: 50px;
        .item {
          display: flex;
          align-items: center;
          background-color: #F6F6F8;
          padding: 0 30px;
          background-image: url("../img/vector-file.svg");
          background-position: 5%;
          background-repeat: no-repeat;
          margin-bottom: 11px;
          @media only screen and (max-width: $r-768 - 1) {
            padding: 5px 20px 5px 20px;
            background-image: url("../img/vector-file-mobile.svg"); }
          &-icon {
            margin-left: 15px;
            img {
              height: 33px; } }
          &-flex {
            display: flex;
            @media only screen and (max-width: $r-768 - 1) {
              flex-direction: column;
              .item-name {
                order: 2; }
              .item-size {
                order: 1; } } }
          &-name {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
            margin: 0 0 0 17px;
            color: $blue-dark;
            @media only screen and (max-width: $r-768 - 1) {
              font-size: 16px;
              line-height: 1.2; } }
          &-size {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 30px;
            color: rgba(26, 21, 55, 0.55);
            @media only screen and (max-width: $r-768 - 1) {
              font-size: 13px;
              line-height: 1.2; } } } } } } }
