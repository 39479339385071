.page-id-133 {
  .header-navigation {
    background-color: transparent; }
  .nav-up {
    .header-navigation {
      background-color: transparent; } } }

// Invoicing hero

.invoicing-hero {
  position: relative;
  padding: 155px 0 95px 0;
  min-height: 610px;
  background-size: cover;
  background-position: bottom;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 185px 0 35px 0; }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 21, 55, 0.70);
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0 70%);
    @media only screen and (max-width: $r-992) {
      clip-path: polygon(0 0, 100% 0, 100% 95%, 0 79%); }
    @media only screen and (max-width: $r-768 - 1) {
      clip-path: polygon(0 0, 100% 0, 100% 95%, 0 89%); } }
  //&.add-closed
  //  margin-top: 49px
  .text {
    padding-top: 20px;
    text-align: right;
    .title-main {
      font-size: 52px;
      line-height: 62px;
      margin-bottom: 9px;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 40px;
        line-height: 47px; } }
    h3 {
      max-width: 480px;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 49px;
      margin-bottom: 32px;
      color: white;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 22px;
        line-height: 32px; } }
    .btn-orange {
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 55px; } } }
  .image {
    position: relative;
    .vectors-left {
      position: absolute;
      bottom: -51px;
      left: -131px;
      z-index: 1;
      svg {
        g {
          &:first-of-type {
            path, rect {
              animation: rotatedelement 15s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement 6s infinite linear; } }
          &:nth-of-type(3) {
            path, rect {
              animation: movedelement2 7s infinite linear; } }
          &:nth-of-type(4) {
            path, rect {
              animation: movedelement2 3s infinite linear; } }
          &:nth-of-type(5) {
            path, rect {
              animation: rotatedelement 9s infinite linear; } }
          &:nth-of-type(6) {
            path, rect {
              animation: rotatedelement2 10s infinite linear; } }
          &:nth-of-type(7) {
            path, rect {
              animation: rotatedelement 13s infinite linear; } }
          &:nth-of-type(8) {
            path, rect {
              animation: rotatedelement2 13s infinite linear; } }
          &:nth-of-type(9) {
            path, rect {
              animation: movedelement 8s infinite linear; } }
          &:nth-of-type(10) {
            path, rect {
              animation: movedelement2 10s infinite linear; } } } } } } }

// Invoicing included

.invoicing-included {
  padding: 90px 0 70px 0;
  background-color: white;
  @media only screen and (max-width: $r-768 - 1) {
    margin-top: -1px;
    padding: 65px 0 50px 0; }
  .text {
    text-align: right;
    margin-bottom: 68px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 30px; }
    .title-secondary {
      max-width: 855px;
      margin-bottom: 17px;
      &:before {
        top: 26px; } }
    p {
      font-size: 26px;
      line-height: 31px;
      color: $blue-dark; } }
  .wrapper {
    .item {
      min-height: 354px;
      padding: 60px 20px 20px 20px;
      margin-bottom: 60px;
      background-color: white;
      box-shadow: 5px 10px 25px rgba(190, 196, 250, 0.3);
      text-align: center;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 30px;
        padding: 30px; }
      .icon {
        margin-bottom: 30px;
        svg,
        img {
          height: 115px; } }
      p {
        font-size: 18px;
        line-height: 21px;
        max-width: 230px;
        margin: 0 auto;
        color: $blue-dark; } } }
  .line {
    width: 880px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media only screen and (max-width: $r-992) {
      max-width: 80%; }
    &:before {
      position: absolute;
      content: '';
      top: 4px;
      width: 100%;
      height: 2px;
      background-color: #E9E5F6; }
    span {
      width: 30px;
      height: 10px;
      display: flex;
      position: relative;
      z-index: 1;
      &:first-child {
        background-color: $blue-dark; }
      &:nth-of-type(2) {
        background-color: $orange; }
      &:nth-of-type(3) {
        background-color: $yellow; } } } }

// Invoicing tabs

.invoicing-tabs {
  padding: 70px 0;
  background-color: white;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 50px 0; }
  .text {
    text-align: right;
    margin-bottom: 72px;
    .title-secondary {
      max-width: 800px;
      &:before {
        top: 26px; } } } }

// Invoicing pricing

.invoicing-pricing {
  position: relative;
  background-color: $orange;
  padding: 90px 0;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 65px 0 20px 0; }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $yellow;
    clip-path: polygon(0 0, 0% 100%, 100% 100%); }
  .text {
    text-align: center;
    margin-bottom: 63px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 30px; }
    .title-secondary {
      width: 100%;
      font-size: 41px;
      line-height: 48px;
      padding-left: 0;
      text-align: center;
      justify-content: center;
      color: white;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 27px;
        line-height: 34px; }
      &:before {
        display: none; } } }
  .wrapper {
    position: relative;
    .vectors {
      position: absolute;
      top: 20px;
      width: 100%;
      text-align: center;
      z-index: 1;
      @media only screen and (max-width: $r-768 - 1) {
        display: none; }
      img {
        max-width: 80%; } }
    div[class*="col-"] {
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 30px; } }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      background-color: white;
      box-shadow: 0 0 40px rgba(200, 112, 30, 0.4);
      padding: 215px 0 40px 0;
      @media only screen and (max-width: $r-768 - 1) {
        padding: 30px 15px; }
      &:hover {
        background-color: transparent;
        .item-price {
          color: $blue-dark; }
        .item-btn {
          .btn-orange {
            background-color: white;
            color: $blue-dark;
            &:hover {
              background-color: $orange;
              color: white; } } } }
      &-price {
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 1;
        text-align: center;
        margin-bottom: 0;
        color: $orange;
        span {
          font-size: 30px;
          margin-right: 5px; } }
      &-title {
        min-height: 43px;
        margin-bottom: 20px;
        @media only screen and (max-width: $r-768 - 1) {
          min-height: auto; }
        h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 29px;
          line-height: 150%;
          text-align: center;
          color: $blue-dark;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 23px; } } }
      &-text {
        max-width: 200px;
        margin: 0 auto 21px auto;
        text-align: right;
        @media only screen and (max-width: $r-768 - 1) {
          max-width: 100%;
          margin-bottom: 30px; }
        p,
        ul li,
        ol li {
          font-size: 18px;
          line-height: 150%;
          color: $blue-dark;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 15px;
            margin-bottom: 5px; } }
        ul li {
          position: relative;
          padding-right: 18px;
          &:before {
            position: absolute;
            content: '';
            right: 0;
            top: 8px;
            width: 10px;
            height: 8px;
            background-image: url("../img/icon-check.svg");
            background-size: cover;
            @media only screen and (max-width: $r-768 - 1) {
              top: 6px; } } } }
      &-btn {
        text-align: center; } } } }
