// Home

.home-hero {
  position: relative;
  padding: 155px 0 25px 0;
  min-height: 610px;
  transition: .3s;
  z-index: 1;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 185px 0 35px 0; }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 75%);
    background-color: $blue-dark;
    z-index: -1; }
  //&.add-closed
  //  margin-top: 144px
  .text {
    padding-top: 90px;
    text-align: $right;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: $r-768 - 1) {
      padding-top: 0; }
    .title-main {
      margin-bottom: 27px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 20px; } }
    p {
      max-width: 550px;
      font-size: 36px;
      font-weight: 300;
      line-height: 49px;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 22px;
        line-height: 32px; }
      &:last-of-type {
        margin-bottom: 44px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 36px; } } }
    .btn-orange {
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 55px; } } }
  .image {
    position: absolute;
    top: 0;
    left: -63px;
    max-width: 594px;
    @media only screen and (max-width: $r-768 - 1) {
      position: initial; }
    img {
      width: 100%; }
    .vectors-left {
      position: absolute;
      left: -63px;
      top: 55px;
      svg {
        @media only screen and (max-width: $r-768 - 1) {
          &:first-child {
            display: none; } }
        &:nth-of-type(2) {
          display: none;
          @media only screen and (max-width: $r-768 - 1) {
            display: block; } }
        g {
          &:first-of-type {
            path, rect {
              animation: rotatedelement 15s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement 6s infinite linear; } }
          &:nth-of-type(3) {
            path, rect {
              animation: movedelement2 7s infinite linear; } }
          &:nth-of-type(4) {
            path, rect {
              animation: movedelement2 3s infinite linear; } }
          &:nth-of-type(5) {
            path, rect {
              animation: rotatedelement 9s infinite linear; } }
          &:nth-of-type(6) {
            path, rect {
              animation: rotatedelement2 10s infinite linear; } } } }
      @media only screen and (max-width: $r-768 - 1) {
        left: 0; } }
    .vectors-right {
      position: absolute;
      right: -60%;
      bottom: -16px;
      @media only screen and (max-width: $r-768 - 1) {
        right: 0;
        z-index: -1; }
      svg {
        @media only screen and (max-width: $r-768 - 1) {
          &:first-child {
            display: none; } }
        &:nth-of-type(2) {
          display: none;
          @media only screen and (max-width: $r-768 - 1) {
            display: block; } }
        g {
          position: relative;
          &:first-of-type {
            path, rect {
              animation: rotatedelement 25s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement 6s infinite linear; } }
          &:nth-of-type(3) {
            path, rect {
              animation: movedelement 7s infinite linear; } }
          &:nth-of-type(4) {
            path, rect {
              animation: movedelement2 8s infinite linear; } }
          &:nth-of-type(5) {
            path, rect {
              animation: rotatedelement 9s infinite linear; } }
          &:nth-of-type(6) {
            path, rect {
              animation: rotatedelement2 6s infinite linear; } }
          &:nth-of-type(7) {
            path, rect {
              animation: rotatedelement 18s infinite linear; } }
          &:nth-of-type(8) {
            path, rect {
              animation: movedelement 6s infinite linear; } }
          &:nth-of-type(9) {
            path, rect {
              animation: movedelement 17s infinite linear; } } } } }
    .parallax {
      position: absolute;
      left: -63px;
      bottom: -88px;
      @media only screen and (max-width: $r-768 - 1) {
        left: 0;
        svg {
          width: 86.86px;
          height: 107.98px; } } } } }

// Home products

.home-products {
  padding: 140px 0 70px 0;
  background-color: white;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 65px 0 50px 0; }
  .align {
    text-align: $right; }
  .title-secondary {
    margin-bottom: 73px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 41px; } }
  .more-products {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    position: relative;
    padding-bottom: 4px;
    color: $orange;
    @media only screen and (max-width: $r-768 - 1) {
      font-size: 16px;
      line-height: 18px;
      margin-top: 20px;
      display: inline-flex; }
    &:hover {
      text-decoration: none; }
    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $orange; } }
  .wrapper {
    div[class*="col-"] {
      padding-left: 7px;
      padding-right: 7px;
      @media only screen and (max-width: $r-768 - 1) {
        padding-left: 15px;
        padding-right: 15px; } }
    .item {
      height: 370px;
      text-align: $right;
      background-position: center;
      background-size: cover;
      padding: 90px 30px 30px 30px;
      margin-bottom: 42px;
      position: relative;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 16px; }
      &:hover {
        transition: .3s;
        .opened {
          opacity: 1;
          visibility: visible;
          z-index: 1; } }
      .icon {
        margin-bottom: 19px; }
      .title-third {
        margin-bottom: 16px; }
      p {
        max-width: 246px;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px; }
      .more {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 64px;
        height: 64px;
        background-color: $orange;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: white;
        &:hover {
          text-decoration: none; } }
      .opened {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $orange;
        width: 100%;
        height: 100%;
        padding: 35px 30px 95px 30px;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        transition: .3s;
        &:before {
          position: absolute;
          content: '';
          top: -40px;
          right: 30px;
          height: 76px;
          width: 95px;
          background-image: url("../img/bg-opened-product.svg");
          background-size: cover; }
        .btn-blue-dark {
          position: absolute;
          left: 0;
          bottom: 0; } } } } }

// Home numbers

.home-numbers {
  padding: 70px 0 155px 0;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: white;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 50px 0 90px 0; }
  .container {
    @media only screen and (max-width: $r-768 - 1) {
      padding: 0; } }
  .row {
    align-items: center; }
  .wrapper {
    align-items: center;
    position: relative;
    padding: 86px 85px 34px 110px;
    z-index: 1;
    @media only screen and (max-width: $r-992) {
      padding: 86px 15px 34px 15px; }
    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $orange;
      clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 92%);
      z-index: -1;
      @media only screen and (max-width: $r-768 - 1) {
        clip-path: polygon(0 0, 100% 4%, 100% 100%, 0 96%); } }
    &:after {
      position: absolute;
      content: '';
      top: 35px;
      left: 0;
      width: 100%;
      height: calc(100% - 70px);
      background-color: $blue-dark;
      z-index: -1; }
    .row {
      div[class*="col-"] {
        &:first-child {
          @media only screen and (max-width: $r-768 - 1) {
            order: 2; } }
        &:nth-of-type(2) {
          @media only screen and (max-width: $r-768 - 1) {
            order: 1; } } }
      .row {
        div[class*="col-"] {
          &:first-child {
            order: 1; }
          &:nth-of-type(2) {
            order: 2; }
          &:nth-of-type(3) {
            order: 3; }
          &:nth-of-type(4) {
            order: 4; } } } }
    .item {
      text-align: $right;
      margin-bottom: 80px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 28px; }
      .item-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        @media only screen and (max-width: $r-768 - 1) {
          align-items: flex-start;
          margin-bottom: 0; }
        .icon {
          margin-left: 14px;
          @media only screen and (max-width: $r-768 - 1) {
            margin-left: 26px; }
          img {
            min-width: 37px;
            width: 37px; } }
        .title-third {
          margin-bottom: 0;
          font-weight: bold;
          font-size: 30px;
          line-height: 36px;
          position: relative;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 24px;
            line-height: 28px;
            margin-top: -5px; }
          &:before {
            position: absolute;
            content: '+';
            left: -20px; } } }
      p {
        padding-right: 50px;
        font-size: 21px;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.6);
        @media only screen and (max-width: $r-768 - 1) {
          margin-top: -5px;
          font-size: 18px;
          line-height: 26px; } } }
    .image {
      position: relative;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 68px; }
      .vector {
        position: absolute;
        left: -40px;
        bottom: -60px; } }
    .parallax {
      position: absolute;
      left: 26px;
      bottom: 0;
      @media only screen and (max-width: $r-768 - 1) {
        top: 35px;
        left: 0;
        bottom: initial; } } }
  .container-full {
    width: 100%;
    position: absolute;
    top: 0;
    svg {
      @media only screen and (max-width: $r-768 - 1) {
        position: relative;
        z-index: 1; }
      g {
        &:first-of-type {
          path, rect {
            animation: rotatedelement 15s infinite linear; } }
        &:nth-of-type(2) {
          path, rect {
            animation: movedelement 6s infinite linear; } }
        &:nth-of-type(3) {
          path, rect {
            animation: movedelement2 7s infinite linear; } }
        &:nth-of-type(4) {
          path, rect {
            animation: movedelement2 3s infinite linear; } }
        &:nth-of-type(5) {
          path, rect {
            animation: rotatedelement 9s infinite linear; } } } } } }

// Home testimonials

.home-testimonials {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #F6F6F8;
  padding: 110px 0 320px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 83px 0 300px 0; }
  .align {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 45px; } }
  .arrows {
    display: flex;
    align-items: center;
    &.show-desctop {
      @media only screen and (max-width: $r-768 - 1) {
        display: none; } }
    &.show-mobile {
      display: none;
      @media only screen and (max-width: $r-768 - 1) {
        display: flex;
        justify-content: center;
        width: 100%; } }
    .swiper-button-next,
    .swiper-button-prev {
      outline: none;
      background-image: none;
      position: initial;
      margin: 0;
      width: auto;
      height: auto;
      @media only screen and (max-width: $r-768 - 1) {
        margin: 0 12px; } }
    .swiper-button-prev {
      margin-right: 23px;
      @media only screen and (max-width: $r-768 - 1) {
        margin-right: 12px; } } }
  .slider {
    width: 100%;
    overflow: hidden;
    .slider-testimonials {
      overflow: initial;
      padding-right: 10%;
      padding-bottom: 26px;
      @media only screen and (max-width: $r-768 - 1) {
        padding-right: 0;
        padding-bottom: 67px; }
      .swiper-slide-active {
        transition: .3s;
        &:before {
          left: 37px!important;
          transform: scale(1) !important; }
        .item {
          &:before {
            opacity: 1!important;
            transition: 1s!important; } } }
      .swiper-slide {
        max-width: 468px;
        position: relative;
        &:before {
          position: absolute;
          content: '';
          width: 85px;
          height: 118px;
          left: 0;
          bottom: -26px;
          background-image: url("../img/vector-slide-center.svg");
          z-index: 1;
          transform: scale(0);
          transition: all 1s ease;
          @media only screen and (max-width: $r-768 - 1) {
            left: 21px;
            bottom: -22px;
            width: 60px;
            height: 83px; } }
        .item {
          width: 100%;
          height: 100%;
          background-color: white;
          padding: 54px 66px 44px 30px;
          text-align: right;
          @media only screen and (max-width: $r-768 - 1) {
            padding: 44px 20px 35px 30px; }
          &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: 1px solid $orange;
            opacity: 0;
            transition: 1s; }
          .item-icon {
            margin-bottom: 41px;
            svg {
              height: 36px; } }
          .text {
            min-height: 208px;
            margin-bottom: 50px;
            p {
              color: rgba(26, 21, 55, 0.7);
              @media only screen and (max-width: $r-768 - 1) {
                font-size: 12px;
                line-height: 22px; } } }
          .item-author {
            display: flex;
            align-items: center;
            img {
              height: 56px;
              margin-left: 23px; }
            .info {
              h3 {
                font-size: 21px;
                font-weight: bold;
                margin-bottom: 6px;
                color: $blue-dark;
                @media only screen and (max-width: $r-768 - 1) {
                  font-size: 18px;
                  line-height: 21px;
                  margin-bottom: 5px; } }
              h4 {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                text-align: right;
                color: $blue-dark;
                @media only screen and (max-width: $r-768 - 1) {
                  font-size: 13px;
                  line-height: 15px; } } } } } } } }
  .container-full {
    width: 100%;
    position: absolute;
    bottom: 350px;
    right: 0;
    text-align: right;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } } }

// Home why

.home-why {
  position: relative;
  padding: 197px 0 125px 0;
  margin-top: -200px;
  background-color: white;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 123px 0 50px 0; }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue-dark;
    clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%);
    @media only screen and (max-width: $r-768 - 1) {
      clip-path: polygon(0 0, 100% 2%, 100% 100%, 0 100%); } }
  .container {
    position: relative;
    z-index: 1; }
  .align {
    text-align: center;
    margin-bottom: 60px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 16px; }
    .title-secondary {
      color: white;
      &:before {
        background-color: white; } } }
  .wrapper {
    align-items: center;
    padding: 20px 0;
    &:nth-of-type(odd) {
      div[class*="col-"] {
        &:first-of-type {
          order: 2; }
        &:nth-of-type(2) {
          order: 1; } } }
    &:nth-of-type(even) {
      @media only screen and (max-width: $r-768 - 1) {
        div[class*="col-"] {
          &:first-of-type {
            order: 2; }
          &:nth-of-type(2) {
            order: 1; } } } }
    &:nth-of-type(5) {
      .image {
        &:before {
          position: absolute;
          content: '';
          background-image: url("../img/vector-why-bottom.svg");
          background-size: cover;
          background-repeat: no-repeat;
          bottom: -24px;
          right: -15px;
          height: 191px;
          width: 119px;
          z-index: 1;
          @media only screen and (max-width: $r-768 - 1) {
            height: 100px;
            width: 50px; } } } }
    .image {
      position: relative;
      @media only screen and (max-width: $r-768 - 1) {
        margin-bottom: 33px; }
      img {
        box-shadow: 5px 10px 25px rgba(233, 187, 145, 0.26); } }
    .text {
      text-align: $right;
      .title-third {
        margin-bottom: 37px;
        @media only screen and (max-width: $r-768 - 1) {
          margin-bottom: 22px; } }
      p {
        max-width: 407px; } } }
  .vectors {
    position: absolute;
    bottom: 72px;
    right: 80px;
    @media only screen and (max-width: $r-768 - 1) {
      right: 0;
      bottom: 140px;
      width: 100%; }
    svg {
      width: 100%;
      g {
        &:first-of-type {
          path, rect {
            animation: rotatedelement 15s infinite linear; } }
        &:nth-of-type(2) {
          path, rect {
            animation: movedelement 6s infinite linear; } }
        &:nth-of-type(3) {
          path, rect {
            animation: movedelement2 7s infinite linear; } }
        &:nth-of-type(4) {
          path, rect {
            animation: movedelement2 3s infinite linear; } }
        &:nth-of-type(5) {
          path, rect {
            animation: rotatedelement 9s infinite linear; } }
        &:nth-of-type(6) {
          path, rect {
            animation: movedelement 9s infinite linear; } } } } }
  .parallax {
      position: absolute;
      top: 240px;
      left: 60px;
      height: 170px;
      width: 210px;
      @media only screen and (max-width: $r-768 - 1) {
        width: 90px;
        height: 110px;
        top: -34px;
        left: 10px; } } }
