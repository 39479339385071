.page-id-80 {
  .header-navigation {
    background-color: transparent; }
  .nav-up {
    .header-navigation {
      background-color: transparent; } } }


// Payslip hero

.payslip-hero {
  position: relative;
  padding: 155px 0 95px 0;
  min-height: 610px;
  background-size: cover;
  background-position: bottom;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 185px 0 95px 0; }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 21, 55, 0.70);
    clip-path: polygon(0 0, 100% 0, 100% 95%, 0 71%);
    @media only screen and (max-width: $r-992) {
      clip-path: polygon(0 0, 100% 0, 100% 95%, 0 79%); }
    @media only screen and (max-width: $r-768 - 1) {
      clip-path: polygon(0 0, 100% 0, 100% 95%, 0 89%); } }
  //&.add-closed
  //  margin-top: 49px
  .text {
    padding-top: 25px;
    text-align: right;
    .title-main {
      font-size: 52px;
      line-height: 62px;
      margin-bottom: 9px;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 40px;
        line-height: 47px; } }
    h3 {
      max-width: 480px;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 49px;
      color: white;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 22px;
        line-height: 32px; } }
    p {
      max-width: 640px;
      font-size: 17px; }
    .btn-orange {
      margin-bottom: 55px; } }
  .image {
    position: relative;
    .vectors-left {
      position: absolute;
      bottom: -51px;
      left: -131px;
      z-index: 1;
      svg {
        g {
          &:first-of-type {
            path, rect {
              animation: rotatedelement 15s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement 6s infinite linear; } }
          &:nth-of-type(3) {
            path, rect {
              animation: movedelement2 7s infinite linear; } }
          &:nth-of-type(4) {
            path, rect {
              animation: movedelement2 3s infinite linear; } }
          &:nth-of-type(5) {
            path, rect {
              animation: rotatedelement 9s infinite linear; } }
          &:nth-of-type(6) {
            path, rect {
              animation: rotatedelement2 10s infinite linear; } } } } } } }

// Payslip testimonial

.payslip-testimonial {
  padding: 110px 0 210px 0;
  margin-top: -2px;
  background-color: white;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 65px 0 50px 0; }
  .container {
    position: relative;
    z-index: 1; }
  .title-secondary {
    max-width: 880px;
    margin: 0 auto 115px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0 0 16px 0;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 41px; }
    &:before {
      width: 112px;
      bottom: 0;
      left: initial; } }
  .wrapper {
    position: relative;
    .item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1005px;
      margin: 0 auto;
      background-color: white;
      box-shadow: 0 10px 32.85px rgba(190, 196, 250, 0.3);
      padding: 90px 65px 100px 65px;
      position: relative;
      @media only screen and (max-width: $r-768 - 1) {
        flex-direction: column;
        padding: 30px 15px; }
      &:before {
        position: absolute;
        content: '';
        top: -30px;
        right: -30px;
        width: calc(100% - 100px);
        height: calc(100% - 53px);
        background: $blue-dark;
        box-shadow: 0px -1px 32.85px rgba(190, 196, 250, 0.3);
        z-index: -1;
        @media only screen and (max-width: $r-768 - 1) {
          top: -15px; } }
      .item-left {
        width: 66%;
        @media only screen and (max-width: $r-768 - 1) {
          width: 100%;
          order: 2; } }
      .item-right {
        width: 30%;
        text-align: center;
        @media only screen and (max-width: $r-768 - 1) {
          width: 100%;
          order: 1; } }
      .parallax-left {
        position: absolute;
        left: -132px;
        bottom: -77px;
        z-index: -1; }
      .parallax-right {
        position: absolute;
        right: -148px;
        top: 45px;
        z-index: -2; }
      .avatar {
        display: inline-flex;
        justify-content: center;
        margin-bottom: 23px;
        position: relative;
        z-index: 1;
        img {
          height: 130px; }
        svg {
          top: -12px;
          right: -14px;
          position: absolute;
          z-index: -1; } }
      .name {
        .title-third {
          font-size: 25px;
          line-height: 30px;
          color: $blue-dark; } }
      .position {
        margin-bottom: 28px;
        p {
          font-size: 19px;
          color: $blue-dark; } }
      .text {
        max-width: 615px;
        margin: 0 auto;
        text-align: right;
        p {
          font-size: 19px;
          line-height: 32px;
          color: $blue-dark;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 15px;
            line-height: 22px; } } } } } }

// Payslip text

.payslip-text {
  background: #F6F6F8;
  padding: 80px 0 95px 0;
  position: relative;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 65px 0 50px 0; }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 40%;
    height: 305px;
    background: $blue-dark;
    @media only screen and (max-width: $r-768 - 1) {
      display: none; } }
  .row {
    div[class*="col-"] {
      &:first-of-type {
        @media only screen and (max-width: $r-768 - 1) {
          order: 2; } }
      &:nth-of-type(2) {
        @media only screen and (max-width: $r-768 - 1) {
          order: 1; } } } }
  .text {
    padding-top: 25px;
    text-align: right;
    @media only screen and (max-width: $r-768 - 1) {
      padding-top: 0; }
    .title-secondary {
      max-width: 600px;
      padding: 14px 0 0 0;
      margin-bottom: 40px;
      @media only screen and (max-width: $r-768 - 1) {
        padding-top: 0;
        margin-bottom: 22px; }
      &:before {
        right: 0;
        left: initial;
        top: 0; } }
    p {
      font-size: 19px;
      line-height: 32px;
      max-width: 560px;
      text-align: right;
      color: $blue-dark;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 15px;
        line-height: 22px; } } }
  .image {
    max-width: 490px;
    position: relative;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 30px; }
    .vectors {
      height: calc(100% + 65px);
      position: absolute;
      left: -74px;
      bottom: -10px;
      z-index: 1;
      svg {
        height: 100%;
        g {
          &:first-of-type {
            path, rect {
              animation: movedelement2 10s infinite linear; } }
          &:nth-of-type(2) {
            path, rect {
              animation: movedelement 6s infinite linear; } }
          &:nth-of-type(3) {
            path, rect {
              animation: movedelement2 7s infinite linear; } }
          &:nth-of-type(4) {
            path, rect {
              animation: movedelement2 3s infinite linear; } } } } } } }

// Payslip included

.payslip-included {
  padding: 68px 0 140px 0;
  position: relative;
  background-color: $blue-dark;
  z-index: 1;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 65px 0 50px 0; }
  .image {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50%;
    z-index: -1;
    @media only screen and (max-width: $r-768 - 1) {
      position: initial;
      padding: 0 15px;
      width: 100%; }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover; } }
  .title-secondary {
    width: 100%;
    font-size: 41px;
    line-height: 49px;
    margin-bottom: 16px;
    text-align: right;
    padding-left: 0;
    color: white;
    @media only screen and (max-width: $r-768 - 1) {
      font-size: 27px;
      line-height: 32px; }
    &:before {
      display: none; }
    b {
      font-weight: 600;
      background-color: $orange;
      padding: 1px 20px; } }
  .text {
    text-align: right;
    padding-right: 70px;
    margin-bottom: 115px;
    @media only screen and (max-width: $r-992) {
      padding-right: 0; }
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 55px; }
    p {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 26px;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 22px;
        line-height: 32px; } } }
  ul {
    text-align: right;
    padding-right: 70px;
    @media only screen and (max-width: $r-992) {
      padding-right: 0; }
    li {
      font-size: 23px;
      line-height: 27px;
      margin-bottom: 55px;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 30px; }
      img {
        margin-left: 37px; } } }
  .parallax {
    position: absolute;
    left: 42px;
    bottom: 80px; }
  .vectors {
    position: absolute;
    left: 0;
    bottom: 0;
    svg {
      g {
        &:first-of-type {
          path, rect {
            animation: rotatedelement 15s infinite linear; } }
        &:nth-of-type(2) {
          path, rect {
            animation: movedelement 6s infinite linear; } } } } } }

// Contact form

.contact-form {
  padding: 110px 0 105px 0;
  background-color: #F6F6F8;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 55px 0 100px 0;
    margin-top: -1px; }
  .show-after {
    display: none;
    padding: 100px 75px 0 0;
    text-align: right;
    @media only screen and (max-width: $r-768 - 1) {
      padding: 30px 0 0 0; }
    .title-secondary {
      font-size: 65px;
      line-height: 77px;
      margin-bottom: 50px;
      padding-top: 14px;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 27px;
        line-height: 34px;
        padding: 0 0 0 90px; }
      &:before {
        width: 73px;
        top: 0;
        right: 0;
        left: initial;
        @media only screen and (max-width: $r-768 - 1) {
          left: 0;
          right: initial;
          top: 18px; } } }
    .text {
      display: flex;
      align-items: center;
      padding-right: 0;
      p {
        max-width: 100%;
        width: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        line-height: 32px;
        margin-bottom: 0;
        color: $orange;
        @media only screen and (max-width: $r-768 - 1) {
          font-size: 15px;
          line-height: 20px; } }
      svg {
        margin-left: 30px; } } }
  .text {
    text-align: right;
    padding-right: 75px;
    margin-bottom: 48px;
    @media only screen and (max-width: $r-992) {
      padding-right: 0; }
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 30px;
      padding-right: 30px; }
    .title-secondary {
      padding-top: 14px;
      margin-bottom: 11px;
      @media only screen and (max-width: $r-768 - 1) {
        font-size: 27px;
        padding: 0 0 0 90px; }
      &:before {
        right: 0;
        top: 0;
        @media only screen and (max-width: $r-768 - 1) {
          width: 73px;
          right: initial;
          left: 0;
          top: 21px; } } }
    p {
      max-width: 340px;
      color: $blue-dark; } }
  .image {
    margin-bottom: 30px;
    @media only screen and (max-width: $r-768 - 1) {
      margin-bottom: 45px; }
    &.show-desctop {
      display: block;
      @media only screen and (max-width: $r-768 - 1) {
        display: none; } }
    &.show-mobile {
      display: none;
      @media only screen and (max-width: $r-768 - 1) {
        display: block; } } }
  .form {
    padding-right: 75px;
    @media only screen and (max-width: $r-992) {
      padding-right: 0; }
    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      direction: rtl;
      .wpcf7-response-output {
        width: 100%;
        margin: 30px 0 0 0;
        font-size: 12px;
        border-color: $orange;
        text-align: right;
        color: $blue-dark; }
      .wpcf7-not-valid-tip {
        font-size: 14px;
        position: absolute;
        right: 0; }
      label {
        width: 100%;
        max-width: 48%;
        margin: 0 0 18px 0;
        @media only screen and (max-width: $r-768 - 1) {
          max-width: 100%; }
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          text-align: right;
          padding-right: 15px;
          color: $blue-dark;
          @media only screen and (max-width: $r-768 - 1) {
            font-size: 15px;
            line-height: 17px;
            margin-bottom: 2px; } }
        input,
        select,
        textarea {
          width: 100%;
          height: 48px;
          background-color: white;
          box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
          border-radius: 6px;
          margin-top: 10px;
          border: 0;
          padding: 0 15px;
          outline: none;
          appearance: none;
          resize: none;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          color: $blue-dark; }
        select {
          background-image: url("../img/icon-select.svg");
          background-position: 5%;
          background-repeat: no-repeat; }
        textarea {
          min-height: 161px;
          padding-top: 15px; } }
      .wrapper {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: $r-768 - 1) {
          flex-direction: column; }
        .item {
          width: 100%;
          max-width: 48%;
          @media only screen and (max-width: $r-768 - 1) {
            max-width: 100%; }
          label {
            width: 100%;
            max-width: 100%;
            margin-left: 0; } } }
      .btn-submit {
        width: 100%;
        text-align: left;
        position: relative;
        .wpcf7-spinner {
          position: absolute;
          left: -15px;
          top: 10px; }
        input {
          border: 0;
          min-width: 120px;
          height: 48px;
          border-radius: 0; } } } } }
