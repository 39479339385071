// Colors

$blue-light: #241D4E;
$blue-dark: #1A1537;
$orange: #F48A28;
$yellow: #FECE00;

// Align

$right: right;

// Global

html {
  margin-top: 0!important; }

html,
body {
  overflow-x: hidden; }

body.archive,
body.single {
  overflow: initial; }

body {
  font-family: 'Rubik', sans-serif!important;
  background-color: $blue-dark!important;
  .container {
    max-width: 1200px; }
  svg {
    overflow: visible; } }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

img {
  max-width: 100%; }

p {
  line-height: 26px; }

p, ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: white; }

.check {
  ul {
    li {
      position: relative;
      padding-right: 26px;
      margin-bottom: 6px;
      &:before {
        position: absolute;
        content: '';
        right: 0;
        top: 5px;
        width: 12px;
        height: 10px;
        background-image: url("../img/icon-check.svg");
        background-size: cover;
        background-repeat: no-repeat; } } } }

.container-full {
  max-width: 1920px;
  margin: 0 auto;
  position: relative; }

.title-main {
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 71px;
  color: white;
  @media only screen and (max-width: $r-768 - 1) {
    font-size: 40px;
    line-height: 47px; } }

.title-secondary {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 53px;
  text-align: right;
  position: relative;
  padding-left: 90px;
  color: $blue-dark;
  @media only screen and (max-width: $r-768 - 1) {
    font-size: 27px;
    line-height: 34px;
    padding-left: 48px; }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    width: 71px;
    height: 1px;
    background-color: $blue-dark;
    @media only screen and (max-width: $r-768 - 1) {
      width: 39px; } } }

.title-third {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: white; }

.btn-orange {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 225px;
  height: 58px;
  background-color: $orange;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  padding: 0 24px;
  transition: .3s;
  color: white;
  &:hover {
    text-decoration: none;
    background-color: white;
    transition: .3s;
    color: $orange; } }

.btn-blue-dark {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 213px;
  height: 58px;
  background-color: $blue-dark;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  transition: .3s;
  color: white;
  &:hover {
    text-decoration: none;
    background-color: white;
    transition: .3s;
    color: $blue-dark; } }

// Breadcrumbs

.breadcrumbs {
  padding: 70px 0 20px 0;
  background-color: white;
  @media only screen and (max-width: $r-768 - 1) {
    padding: 50px 0 15px 0; }
  .item {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      position: relative;
      padding: 0 10px;
      color: $blue-dark;
      @media only screen and (max-width: $r-768 - 1) {
        display: inline-block;
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 5px 0 10px; }
      &:before {
        position: absolute;
        content: '/';
        left: -3px;
        font-weight: 400;
        font-size: 15px;
        color: $blue-dark;
        @media only screen and (max-width: $r-768 - 1) {
          left: 0; } }
      &:first-child {
        padding-right: 0; }
      &:last-of-type {
        &:before {
          display: none; } }
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: $blue-dark; } } } }


// Elementor

body {
  .elementor-widget:not(:last-child) {
    margin-bottom: 0; } }

// Keyframes

@keyframes movedelement {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(10px, 10px); }
  50% {
    transform: translate(5px, 5px); }
  75% {
    transform: translate(10px, -5px); }
  100% {
    transform: translate(0, 0); } }

@keyframes movedelement2 {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(5px, 5px); }
  50% {
    transform: translate(10px, 10px); }
  75% {
    transform: translate(-5px, 10px); }
  100% {
    transform: translate(0, 0); } }

@keyframes rotatedelement {
  0% {
    transform: translate(0, 0) rotate(0deg); }
  25% {
    transform: translate(-10px, 10px) rotate(10deg); }
  50% {
    transform: translate(5px, -5px) rotate(25deg); }
  75% {
    transform: translate(15px, -5px) rotate(15deg); }
  100% {
    transform: translate(0, 0) rotate(0deg); } }

@keyframes rotatedelement2 {
  0% {
    transform: translate(0, 0) rotate(0deg); }
  25% {
    transform: translate(5px, 5px) rotate(4deg); }
  50% {
    transform: translate(10px, 10px) rotate(5deg); }
  75% {
    transform: translate(20px, 20px) rotate(6deg); }
  100% {
    transform: translate(0, 0) rotate(0deg); } }
